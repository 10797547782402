<template>
	<div id="wholesaleOrderDetail">
		<div class="el-content">
			<div class="order-steps">
                <a-steps :current="active">
                    <a-step title="买家下单" :description="detail.create_time" />
                    <a-step title="买家付款" :description="detail.pay_time" />
                    <a-step title="商家发货" :description="detail.send_time" />
                    <a-step title="订单完成" :description="detail.confirm_time" />
                </a-steps>
			</div>
			<div class="wholesale-order-detail">
				<div class="wodt-top">
					<div class="wodt-top-left">
						<span class="wodt-status">{{getPayStatus}}</span>
						<span class="wodt-number">订单号 : {{order.order_number}}</span>
						<span class="wodt-number">下单时间 : {{order.create_time}}</span>
					</div>
					<!-- 操作 -->
					<div class="wodt-top-right">
						
						<com-deliver-goods
							v-if="(detail.is_pay==1 || order.is_redit==1)&& order.is_send==0"
							btn-type="link"
							btn-size="small" 
							btn-text="[发货]"
							:id="order.id" 
							:type="1" 
							:is-shop="0" 
							@success="()=>{getOrderDetail()}">
						</com-deliver-goods>
						<span v-if="detail.is_pay==1" @click="refundOrder">[退款]</span>
					</div>
				</div>
                <a-table :pagination="false" :data-source="order.detail" row-key="id" :columns="[
                    {title:'ID',dataIndex:'id'},
                    {title:'封面',dataIndex:'cover',slots:{customRender:'cover'}},
                    {title:'商品名称',dataIndex:'goods_name'},
                    {title:'商品规格',dataIndex:'spec',slots:{customRender:'spec'}},
                    {title:'数量',dataIndex:'count'},
                    {title:'单价',dataIndex:'price'},
                    {title:'金额小计',dataIndex:'total',slots:{customRender:'total'}},
                    {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                ]" >
                    <template #cover="{record}"><a-avatar :src="record.cover"></a-avatar></template>
                    <template #spec="{record}">
                        <div v-if="record.goods_spec_id>0">
                            <span v-for="(item,index) in record.snap_goods_spec.sku" :key="index">{{item.spec_value}};</span>
                        </div>
                    </template>
                    <template #total="{record}">￥{{record.price*record.count}}</template>
                </a-table>
                <div class="wodt-total">
					应付金额：<span>{{order.total_price}}</span>
				</div>
				
				
				<!-- 订单其他信息 -->
				<div class="wodt-info">
					<a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 8 }">
						<a-form-item label="收货信息">
							<span>{{order.name}}/{{order.phone}} {{order.address}}</span>
							<a-button type="link" @click="showDeliveryDialog=true" v-has="{action:'wxapp_marketing_wholesale_update_address'}">[修改]</a-button>
						</a-form-item>
						<a-form-item label="支付方式">
							<span>{{$util.payMethod(detail.pay_method)}}</span>
							
						</a-form-item>
						<a-form-item label="配送信息" v-if="order.is_send == 1">
							<span>{{order.express_company}} / {{order.express_no}}</span>
						</a-form-item>
						<a-form-item label="备注说明">
							<span>{{order.manager_remark}}</span>
							<com-set-remark 
								btn-text="[备注]" 
								btn-type="link"
								btn-size="small" 
								remark-type="goods-order"
								postApi="doSomethingByOrder" 
								:remarkValue="order.manager_remark"
								:params="{
									pk:order.id,
									type:5 ,
									order_type:1
								}" @success="()=>{getOrderDetail()}">
							</com-set-remark>		
						</a-form-item>
					</a-form>	
				</div>
			</div>
		</div>
		
		<!-- 修改收货地址 -->
		<a-modal v-model:visible="showDeliveryDialog" title="修改收货地址" @ok="saveOrderDelivery">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="收货人">
					<a-input v-model:value="deliveryForm.name"></a-input>
				</a-form-item>
				<a-form-item label="联系电话">
					<a-input v-model:value="deliveryForm.mobile"></a-input>
				</a-form-item>
				<a-form-item label="收货地址">
					<a-input v-model:value="deliveryForm.address"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
import wholesaleModel from '@/api/addons/wholesale'
import { useRoute } from 'vue-router'
import statusModel from '@/util/status'
import { $post } from '@/api/http'
import tool from '@/util/tool'
import comSetRemark from '@/components/miniapp/com-set-remark.vue'
import comDeliverGoods from '@/components/miniapp/com-deliver-goods.vue'
import goodsModel from '@/api/goods'
export default {
	components:{
		comSetRemark,
		comDeliverGoods
	},
    setup(){
        const state = reactive({
            list: [],
            showRemarkDialog:false,
            showDeliveryDialog:false,
            deliveryForm:{
                address:'',
                name:'',
                address:''
            },
            manager_remark:'',
            id:0,
            active:1,
            detail:{},
            order:{},
        })

        const getPayStatus = computed(()=>{
            return state.detail.is_pay == 1 ? '已支付':'未支付'
        })
        const opt = useRoute().query
        getOrderDetail()

        function getOrderDetail(){
            wholesaleModel.getWholesaleOrderDetail(opt.id,res=>{
                state.detail = res
                state.order = res.order
                if( state.order.order_status == 0 ) state.active =1 
                if( state.order.order_status == 1 ) state.active =2 
                if( state.order.order_status == 2 ) state.active =3 
                if( state.order.order_status == 3 ) state.active =4
               
                state.order.order_status_show = statusModel.getShopOrderStaus(state.order)
                state.deliveryForm.name = state.order.name
                state.deliveryForm.mobile = state.order.mobile
                state.deliveryForm.address = state.order.address
                state.manager_remark = state.order.manager_remark
                state.order.detail.map(item=>{
					if( typeof item.snap_goods_spec =='string'){
						item.snap_goods_spec = JSON.parse(item.snap_goods_spec)
					}
                })
            })
        }

		function saveOrderDelivery(){
			let form = JSON.parse(JSON.stringify(state.deliveryForm))
			form.pk = state.order.id
			form.type = 4
			form.order_type = 1
			form.express_company = state.order.express_company
			form.express_no = state.order.express_no
			tool.confirm("确认更新该订单的收货信息吗？").then(res=>{
				$post("doSomethingByOrder",form).then(()=>{
					state.showDeliveryDialog = false
					getOrderDetail()
				})
			}).catch(()=>{})
			
		}

		function refundOrder(){
			goodsModel.handleOrder(state.order.id,'refund',"确认要对该订单进行退款操作吗?",()=>{
				getOrderDetail()
			})
		}

        return{
            ...toRefs(state),
            getPayStatus,
            getOrderDetail,
			saveOrderDelivery,
			refundOrder
        }
    }
}
</script>

<style lang="scss">
	// 公共
	.wodt-top {
		width: 100%;
		height: 40px;
		line-height: 40px;
		display: flex;
		justify-content: space-between;
		&-left{
			span:first-child {
				font-weight: bold;
				font-size: 15px;
				color: #ef855e;
				margin-right: 10px;
			}
		}
	
		.wodt-number,
		.wodt-store {
			color: #4e4d4d;
			font-size: 14px;
			margin-right: 20px;
		}
		.wodt-top-right{
			span{
				color: #0074D9;
				cursor: pointer;
				font-size: 14px;
			}
		}
	}
	
	// 订单详情
	.wholesale-order-detail {
		.wodt-total{
			padding: 0 5%;
			height: 60px;
			line-height: 60px;
			text-align: right;
			border: 1px solid #eceff6;
			border-top: none;
			font-size: 14px;
			color: #868686;
			span{
				font-size: 17px;
				color: orangered;
			}
		}
		.wodt-info{
			.el-form-item{
				margin-bottom: 0px;
			}
		}
		
		
	}
	
	// 出库发货记录
	.wholesale-order-second{
		.wos-title{
			padding: 0 2%;
			height: 60px;
			line-height: 60px;
			background: #eef1f9;
			border: 1px solid #eceff6;
			color: #484848;
			font-size: 15px;
			
			span{
				margin-left: 10px;
			}
		}
		.wos-info{
			padding: 0 2%;
			height: 40px;
			line-height: 40px;
			border: 1px solid #eceff6;
			color: #484848;
			font-size: 15px;
			border-bottom: none;
			display: flex;
			justify-content: space-between;
			
			div{
				font-size: 14px;
			}
		}
		
	}

	// 收款记录
	.wholesale-order-pay{
		.wop-top{
			width: 100%;
			display: flex;
			justify-content: space-between;
			font-size: 12px;
			color: #415161;
			
			&-left{
				&-li{
					height: 30px;
					line-height: 30px;
					.wop-price{
						font-weight: bold;
						margin-right: 20px;
						color: #4a4a4a;
					}
				}
			}
			&-right{
				text-align: right;
				line-height: 60px;
			}
		}
	}
</style>
